import { apiUrl } from '../../../constants/api-urls';
import { MethodType } from '../../../constants/common';
import callingApi from '../../../services/apiService';
import { redirectLogOutUrl } from '../../../utils/helperUtil';
import { clearDetails } from '../../loop-qual-app/actions';
import { hideLoader, showLoader } from '../loader/actions';

export const customCreateAction = (type) => {
  const callback = (payload) => ({ type, payload });
  callback.type = type;
  if (type) return callback;
};
export const updateSessionView = customCreateAction('UPDATE_SESSION_VIEW');
export const setTimerValue = customCreateAction('SET_TIMER_VALUE');
export const resetSessionView = customCreateAction('RESET_SESSION_VIEW');
export const prospectExpiredView = customCreateAction('PROSPECT_EXPIRED_SESSION_VIEW');
export const loggedInExtendedView = customCreateAction('LOGGEDIN_EXTENDED_SESSION_VIEW');
export const loggedInExpiredView = customCreateAction('LOGGEDIN_EXPIRED_SESSION_VIEW');
export const updateElapseView = customCreateAction('UPDATE_ELAPSE_TIMER');
export const resetElapseTimer = customCreateAction('RESET_ELAPSE_TIMER');
export const setWarningValue = customCreateAction('SET_WARNING_VALUE');
export const setMainTimeStamp = customCreateAction('SET_MAIN_TIMESTAMP');

export const sessionKeepAlive = async (dispatch, loopQualApp) => {
  try {
    dispatch(showLoader());
    let flowName = '';
    if (loopQualApp.isLoggedIn) {
      flowName = `AAL`;
    } else {
      flowName = `NSE`;
    }
    const requiredflowType = { flowType: flowName };
    const url = apiUrl().sessionKeepAlive;
    const response = await callingApi(url, MethodType.POST, {}, 'application/json', requiredflowType);
    dispatch(hideLoader());
    console.log('session_alive response', response);
  } catch (e) {
    dispatch(hideLoader());
    console.log(e);
  }
};

export const sessionLogout = async (dispatch, loopQualApp) => {
  try {
    dispatch(showLoader());
    let flowName = '';
    if (loopQualApp.isLoggedIn) {
      flowName = `AAL`;
    } else {
      flowName = `NSE`;
    }
    const requiredflowType = { flowType: flowName };
    const url = apiUrl().sessionLogoff;
    const response = await callingApi(url, MethodType.POST, {}, 'application/json', requiredflowType);
    // dispatch(hideLoader());
    clearDetails(dispatch, null, null, loopQualApp);
    redirectLogOutUrl();
    console.log('session_logoff response', response);
  } catch (e) {
    dispatch(hideLoader());
    console.log(e);
  }
};
