import api from './apiGateway';
import { getReqHeader } from '../utils/lqServiceUtil';
import { LQ_VIEWS } from '../constants/common';
import { openModalWindow } from '../components/common/modal/action';
import { loggedInExpiredView } from '../components/common/SessionTimeout/action';
import { updateCurrentView, updateSkeleton } from '../components/loop-qual-app/actions';
import { hideLoader } from '../components/common/loader/actions';
import { openViewVzTag } from '../utils/unifiedVzTag';
import { logMetrics } from './logService';
import { getHomeLQSettings } from '../config';

const callingApi = (url, methodType, data, contentType, reqHeader, digiSession = null) =>
  new Promise((resolve, reject) => {
    const requestObj = getReqHeader(url, methodType, data, contentType, reqHeader, '', digiSession);
    if (window.location.host.includes('localhost')) {
      // to prevent preflight issue for localhost
      requestObj.withCredentials = false;
    }
    api(requestObj)
      .then((response) => {
        console.log('authResponse', url, response);
        if (url.indexOf('soe/digital/auth') > -1 && response?.data?.goToUrl) {
          window?.vzlqw?.dispatch(loggedInExpiredView());
          window?.vzlqw?.dispatch(updateCurrentView(LQ_VIEWS.SESSION_TIMEOUT_VIEW));
          const isTimeoutLogged = sessionStorage.getItem('LQtimeout');
          if (!isTimeoutLogged) {
            logMetrics('soeAuth', 'timeout', 'Info');
            sessionStorage.setItem('LQtimeout', 'true');
          }
          openModalWindow(getHomeLQSettings() ? 'HOMELQ' : 'LQAPP', window?.vzlqw?.dispatch);
          openViewVzTag('LQ logged in session expired');
          window?.vzlqw?.dispatch(hideLoader());
          window?.vzlqw?.dispatch(updateSkeleton(false));
        } else {
          resolve(response);
        }
      })
      .catch((error) => {
        if (window.location.host.includes('localhost')) {
          // no reject
          // reject(error);
        } else {
          reject(error);
        }
        console.log(url, error);
      });
  });

export default callingApi;
