export const unitsDropdownLimit = 10;
export const unitDropDownDefaults = ["I don't live in a unit", 'I can’t find my unit'];
export const lqServices = {
  fiveg: '5G Home Internet',
  lte: 'LTE Home Internet',
  fios: 'Fios Home Internet',
  vshi: '5G Home Internet',
  all: 'all',
};
export const platforms = {
  fiveg: 'FIVEG',
  lte: 'LTE',
  fios: 'FIOS',
  all: 'ALL',
};
export const stickyBannerContent = {
  default: 'Get Verizon Home Services',
  default_5g: `Get ${lqServices.fiveg}`,
  default_lte: `Get ${lqServices.lte}`,
  default_fios: `Get ${lqServices.fios}`,
  button_CA: 'Get started',
  button_Order: 'Order now',
  button_Start: 'Get started',
  button_resume: 'Resume cart',
  button_sign_up: 'Sign up',
  button_sign_up_new: 'Get updates',
  default_bulk: 'Verizon Home Internet is available at your address.',
  fwa_existing: 'This address already has 5G Home Internet. Click on check availability to add a new address to your account.',
  continue: 'Continue',
};

export const captchaAttributesNSA = {
  reCaptchaJS: 'https://www.google.com/recaptcha/api.js?onload=recaptchaOnloadCallback&amp;render=explicit',
  reCaptchaSiteKey: '6LcF73AUAAAAAO93D3bv7MMxkqfBROEEVZbnjdTE',
};

export const recaptchaScript = () => 'https://www.google.com/recaptcha/api.js';

export const hereMapScript = {
  mapCoreScript: 'https://js.api.here.com/v3/3.1/mapsjs-core.js',
  mapServiceScript: 'https://js.api.here.com/v3/3.1/mapsjs-service.js',
  mapUiScript: 'https://js.api.here.com/v3/3.1/mapsjs-ui.js',
  mapEventsScript: 'https://js.api.here.com/v3/3.1/mapsjs-mapevents.js',
};
