import { getUnitForAddress } from '../../services/lqService';
import { UnitLookUp as Action } from '../../constants/actionTypes';
import { CommonLQ } from '../../constants/common';
import { filterByContains } from '../../utils/lqServiceUtil';
import { unitsDropdownLimit, unitDropDownDefaults } from '../../constants/lq-constants';
import { linkVzTag } from '../../utils/unifiedVzTag';
import { hideLoader } from '../common/loader/actions';

export const onUnitSelection = (unitDetails) => ({ type: Action.UPDATE_UNIT_DETAILS, value: unitDetails });

export const restUnitDetails = () => ({ type: Action.RESET_UNIT_DETAILS });

export const loadUnits = (streetDetails, dispatch, loopQualApp, unitInput) =>
  new Promise((resolve, reject) => {
    getUnitForAddress(streetDetails, unitInput)
      .then((unitList) => {
        const streetId = streetDetails?.ntasAddrID || streetDetails?.locusID;
        dispatch({ type: Action.UPDATE_FETCHED_UNITS_STREET_ID, value: streetId });

        const { totalHits } = unitList;
        const units = totalHits === 0 || totalHits === null ? [] : unitList.units;
        units?.map((i) => {
          if (!i.ntasSublocation) {
            i.ntasSublocation = i.sublocation;
          }
        });
        if (undefined === unitInput) {
          dispatch({ type: Action.UPDATE_UNIT_REQUIREMENT, value: !(!units || !units.length) });
          dispatch({ type: Action.UPDATE_UNIT_COLLECTION, value: units });
          dispatch(updateUnitAutocompleteList(units));
          if (totalHits > unitsDropdownLimit) {
            dispatch({ type: Action.UPDATE_DROPDOWN_LIMIT_STATUS, value: true });
            if (loopQualApp && loopQualApp.homeLQ) {
              linkVzTag('units more than 10', 'lq banner');
            } else if (loopQualApp && loopQualApp.lq4) {
              linkVzTag('units more than 10', 'lq sticky bar');
            } else {
              linkVzTag('units more than 10', 'lq check avail');
            }
          } else {
            dispatch({ type: Action.UPDATE_DROPDOWN_LIMIT_STATUS, value: false });
            if (totalHits && totalHits < unitsDropdownLimit) {
              if (loopQualApp && loopQualApp.homeLQ) {
                linkVzTag('units less than 10', 'lq banner');
              } else if (loopQualApp && loopQualApp.lq4) {
                linkVzTag('units less than 10', 'lq sticky bar');
              } else {
                linkVzTag('units less than 10', 'lq check avail');
              }
            }
          }
          setTimeout(() => {
            const unitInputField = document.querySelector('#apartmentNumber');
            if (unitInputField) {
              unitInputField.focus();
              if (loopQualApp?.homeLQ) {
                linkVzTag('lq banner apt - clicked', 'lq banner');
              } else if (loopQualApp?.lq4) {
                linkVzTag('lq sticky bar apt - clicked', 'lq sticky bar');
              } else {
                linkVzTag('lq check avail apt - clicked', 'lq check avail');
              }
            }
          }, 500);
        } else {
          dispatch(updateUnitAutocompleteList(units));
        }
        dispatch(hideLoader());
        resolve(unitList.units);
      })
      .catch((error) => {
        dispatch(hideLoader());
        if (!(error.message === 'old Request Cancelled')) {
          dispatch(unitInput ? updateUnitAutocompleteList([]) : resetUnitsCollection());
        }
        reject(error);
      });
  });

export const resetUnitsCollection = () => ({ type: Action.UPDATE_UNIT_COLLECTION, value: null });

export const updateUserInput = (input) => ({ type: Action.UPDATE_UNIT_INPUT, value: input });
export const unitSelected = (input) => ({ type: Action.UNIT_SELECTED, value: input });

export const filterUnits = (input, units) => {
  if (!input) {
    return units;
  }
  const filterKey = units && units.length > 0 && units[0] && units[0].sublocation ? 'sublocation' : 'unit';
  const filteredCollection = filterByContains(filterKey, input, units);
  return updateUnitAutocompleteList(filteredCollection);
};
export const updateUnitAutocompleteList = (collection) => ({ type: Action.UPDATE_UNIT_AUTOCOMPLET_LIST, value: collection || [] });

export const setUnitInputSuccess = (unitSuccess) => ({ type: Action.SET_UNIT_INPUT_SUCCESS, value: unitSuccess });

export const setUnitInputError = (unitError) => ({ type: Action.SET_UNIT_INPUT_ERROR, value: unitError });

export const restoreUnitDefaultsStore = (dispatch) => {
  dispatch({ type: Action.RESTORE_DEFAULTS });
};
export const updateUserApartment = (userApartment) => ({ type: Action.UPDATE_USER_APARTMENT, value: userApartment });
export const getUnitsDropdownDefaults = (key) => unitDropDownDefaults.map((label) => ({ [key]: label }));
export const getSHA256Val = async (dispatch, item) => {
  let hashHex = '';
  const locusID = item.location_id + CommonLQ.shaKey;
  try {
    const msgUint8 = new TextEncoder().encode(locusID);
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
    item.hashedLocationId = hashHex;
    dispatch(onUnitSelection(item));
  } catch (error) {
    console.error('getSHA-256 failed', error);
  }
};
